import React, {FC} from 'react';

import useClientSettings from 'web/hooks/useClientSettings';

const WhereWeDeliver: FC = () => {
  const settings = useClientSettings();

  return (
    <div className="how-it-works-page__content how-it-works-page__deliver">
      <div className="how-it-works-page__subtitle"> Where We Deliver</div>
      <div className="how-it-works-page__delivery-zone-container">
        <iframe
          className="how-it-works-page__delivery-zone"
          frameBorder="0"
          src={settings.deliveryZoneMaps.sfBayArea}
        />
        <iframe
          className="how-it-works-page__delivery-zone"
          frameBorder="0"
          src={settings.deliveryZoneMaps.laArea}
        />
      </div>
    </div>
  );
};

export default WhereWeDeliver;
